export const SEGMENT_LOCATION = 'Listing Builder'
export const FIELD_EDITOR_MIN_WIDTH = 375
export const KEYWORDS_PANEL_MIN_WIDTH = 220

export const FEATURE_KEYS = [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten'
]
export const MAX_FEATURES = FEATURE_KEYS.length
export const DEFAULT_LAST_FEATURE_INDEX = 4

export const LISTINGS_ENDPOINT = 'api/listings_v2'
export const FEATURE_CHARACTER_LIMIT = 200
export const PRODUCT_TITLE_CHARACTER_LIMIT = 200
export const CHARACTER_LIMITS_KEY = 'Listing-Builder-Character-Limits'

export const EXCLUDE_SPECIAL_CHARACTERS = [
  '!',
  '$',
  '?',
  '_',
  '{',
  '}',
  '^',
  '¬',
  '¦'
]

export const EXCLUDE_ARTICLES_AND_PREPOSITIONS = [
  'a',
  'an',
  'and',
  'by',
  'for',
  'in',
  'is',
  'it',
  'not',
  'of',
  'on',
  'or',
  'the',
  'with',
  'without'
]
