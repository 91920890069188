// this looks a bit odd, it's in it's own file to stop a circular dependency
export const INITIAL_GLOBAL_DATA_STATE = {
  initialLoad: {
    isLoading: true,
    completed: false
  },

  user: {
    id: null,
    email: null,
    account_photo: null,
    created_at: null,
    full_name: null,
    invoice_info: {
      address: {}
    },
    membership_permissions: {},
    permissions: {}
  },
  trackerCategories: [],

  modal: null,
  fullScreenOverlay: null,

  // array structure incase we want to add a more
  // robust notification system in the future
  notices: [],
  allTrackedProducts: [],
  onboardingInfo: {
    jspupWarningViewed: true
  },
  membershipInfo: {
    hasActiveMembership: true,
    membershipStatus: 99,
    has_keyword_spy_access: false,
    renewable: null,
    id: 0,
    name: null,
    tracker_limit: 3,
    admin: false,
    trialDays: '',
    price: null,
    billing_frequency: '',
    stripe_plan: {}
  },
  sellerPlans: {
    isLoading: true,
    monthly: [],
    yearly: []
  },
  extensionInfo: {
    name: null,
    code: null,
    price: null,
    onTrial: false
  },
  upsell: {
    isLoading: false,
    completed: false,
    pendingToken: null,
    membershipTypes: 'monthly',
    selectedMembership: {},
    selectedExtension: {}
  },

  variants: null,
  showVariantModal: false,

  featureAccess: {
    database: true,
    nicheHunter: true,
    keywordScout: true,
    supplierDatabase: true,
    remainingSearches: {
      database: null,
      nicheHunter: null,
      keywordScout: null,
      supplierDatabase: null
    }
  },

  featureLimits: {},

  // Feature Flags
  flagData: {},

  // For google tag manager
  purchaseInfo: null,

  // Base notifications on web app feature to notify them of the trial
  // Can be toggled on and off via user interaction with the modal
  trialAlerts: {
    launch: {
      daysRemaining: 0
    }
  },

  isImpersonating: false,
  impersonatingUser: '',

  showProfitCalculator: false,
  selectedProduct: null,

  toggleUpsell: {
    isLoading: false,
    completed: false
  },

  employeeWithAccess: false,
  hasAuthy: false,

  pendo: {
    visitor: {},
    account: {}
  },

  breadCrumbName: null,

  isShowingCIUpgradeBanner: false
}
